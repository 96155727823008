.base-page{
    background-size: 0 0;  /* Image should not be drawn here */
    width: 100%;
    height: 100%;
    position: fixed; /* Or absolute for scrollable backgrounds */   
    background-image: url("../../assets/background.jpg");    
}

.base-page::before{
    content: ""; /* Important */
    z-index: -1; /* Important */
    position: inherit;
    left: inherit;
    top: inherit;
    width: inherit;
    height: inherit;
    background-image: inherit;
    background-size: cover;
    filter: blur(8px);    
}

.text-align-center{
    text-align: center;    
}

.text-color-black{
    color: rgb(78, 3, 3);
    font-weight: bold;
}

.card{
    text-align: center;
    align-self: center;
    width: 450px;
}